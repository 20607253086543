import React, { ReactElement, useContext, useEffect, useState } from 'react'
import Head from 'next/head'
import { useTranslation } from 'next-i18next'

import { Container } from '~/components/core/layout/container'
import { NotFoundMobile, NotFoundTablet } from '~/components/core/notFound'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { vars } from '~/statics'

const NotFound = (): ReactElement => {
	const { isTablet } = useMediaQuery()
	const { t } = useTranslation(['common'])
	const { kobiConfig: { integrationEnabled: isKobiIntegrationEnabled } } = useContext(AppParametersContext)
	const [imgSrc, setImgSrc] = useState<string>('')

	useEffect(() => {
		setImgSrc(isKobiIntegrationEnabled ? `${ window.location.origin }${ vars.images.notFound }` : vars.images.notFound)
	}, [isKobiIntegrationEnabled])

	return (
		<Container>
			<Head>
				<title>{ t('notFoundErrorTitle') }</title>
			</Head>

			{ isTablet ? (
				<NotFoundTablet imgSrc={ imgSrc } />
			) : (
				<NotFoundMobile imgSrc={ imgSrc } />
			) }
		</Container>
	)
}

export { NotFound }
