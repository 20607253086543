import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { Image } from '~/components/core/image'
import { Link } from '~/components/core/link'
import { INotFoundTabletProps } from '~/components/core/notFound'

import styles from './NotFoundTablet.module.scss'

const NotFoundTablet = (props: INotFoundTabletProps): ReactElement => {
	const { imgSrc } = props
	const { t } = useTranslation(['common'])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.mainContent }>
				<div className={ styles.messageContainer }>
					<div className={ styles.sorryMessage }>
						<div className={ styles.highlight }>
							{ t('notFound.ups') }
						</div>

						<span className={ styles.info }>
							{ t('notFound.somethingWentWrongWithEmoji') }
						</span>
					</div>

					<div className={ styles.errorContainer }>
						<div className={ styles.error }>
							{ t('notFound.error') }
						</div>

						<div className={ styles.errorCode }>
							404
						</div>

						<div className={ styles.errorInfo }>
							{ t('notFound.pageNotFound') }
						</div>
					</div>
				</div>

				<div className={ styles.image }>
					<Image
						src={ imgSrc }
						alt={ t('leroyMerlin') }
						width={ 0 }
						height={ 0 }
						loading="eager"
						className={ styles.img }
					/>
				</div>
			</div>

			<div className={ styles.backToMainPage }>
				<Link
					href="/"
					ariaLabel={ t('notFound.backToMainPage') }
				>
					{ t('notFound.backToMainPage') }
				</Link>

				<span className={ styles.useSearch }>
					{ t('notFound.orUseSearch') }
				</span>
			</div>
		</div>
	)
}

export { NotFoundTablet }
