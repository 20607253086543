import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { Image } from '~/components/core/image'
import { Link } from '~/components/core/link'
import { INotFoundMobileProps } from '~/components/core/notFound'

import styles from './NotFoundMobile.module.scss'

const NotFoundMobile = (props: INotFoundMobileProps): ReactElement => {
	const { imgSrc } = props
	const { t } = useTranslation(['common'])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.messageContainer }>
				<div className={ styles.errorCode }>
					404
				</div>

				<div className={ styles.message }>
					<span className={ styles.highlight }>
						{ t('notFound.somethingWentWrong') }
					</span>

					<Link
						href="/"
						ariaLabel={ t('notFound.backToMainPage') }
					>
						{ t('notFound.backToMainPage') }
					</Link>

					<span className={ styles.useSearch }>
						{ t('notFound.orUseSearch') }
					</span>
				</div>
			</div>

			<div className={ styles.image }>
				<Image
					src={ imgSrc }
					alt={ t('leroyMerlin') }
					width={ 0 }
					height={ 0 }
					loading="eager"
					className={ styles.img }
				/>
			</div>
		</div>
	)
}

export { NotFoundMobile }
